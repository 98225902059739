
.login{
    background: rgb(2,0,36);
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(11,48,142,1) 50%, rgba(21,120,214,1) 100%);    height: 100vh;
    display: flex;
}

.login_form {
    max-width: 600px;
    min-width: 300px;
    max-height: 700px;
    width: 80%;
    background-color: #fff;
    display: inline-block;
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
    border-radius: 25px;
    padding: 50px 40px;
}

.logo_login{
    margin: 0 auto;
    display: block;
}


#headerTitle{
    text-align: center;
    font-family: 'open sans', sans-serif;
    padding: 0;
    margin: 0px 0 40px 0;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
}

.formCenter {
    margin: 40px 0 25px 0;
}

.formField {
    margin-bottom: 15px;
  }
  

.formFieldLabel {
    display: block;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    color: #12130f;
    font-family: 'open sans', sans-serif;
  }

.formFieldInput {
    width: 100%;
    background-color: transparent;
    border: none;
    color: #12130f;
    outline: none;
    border: 1px solid #d4d4d4;
    font-size: 14px;
    font-weight: 300;
    margin-top: 0;
}

.formFieldInput::placeholder {
    color: #b1b1b1;
}

.forget_form p,
.ResetPass p{
    font-size: 12px;
    font-family: 'open sans', sans-serif;
    background-color: #eee;
    padding: 20px;
    font-weight: 700;
}